// Here you can add other styles
table{
	&.table-content-center{
		td{
			vertical-align: middle;
		}
	}
	caption{
		caption-side: top;
		span.v-center-table{
			display: inline-block;
			padding-top: 7px;
		}
	}
}
.table-responsive{
	position: relative;
	table{
		th{
			.form-group{
				margin: 0;
				display: inline;
				*{
					display: inline;
				}
			}
		}
		input[type=checkbox].form-control{
			height: 12px;
			width: 12px;
		}
	}
}

.fixed-loading{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0, 0.1);
	z-index: 10;
	text-align: center;
	padding-top: 100px;
	color: white;
}

.datetime{
	cursor: help;
}

.custom-overlay{
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0,0,0, 0.8);
	z-index: 100000;
	.overlay-body{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: white;
		text-align: center;
		h5{
			font-size: 40px;
		}
		h6{
			font-size: 30px;
			color: red;
		}
	}
}

.row-same-height{
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	[class*='col-']{
		display: flex;
		flex-direction: column;
	}
}

.cursor-pointer{
	cursor: pointer;
}

.swatches-picker {
	max-width: 100%;
	width: 100% !important;
}

button {
	white-space: nowrap;
}